import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { ComponentPropsWithRef, forwardRef } from 'react';

export const input = tailwindVariants({
  base: 'rounded border border-grey-5 bg-grey-1 px-2 text-16 text-grey-12 outline-none focus:border-blue-9 focus:ring-1 focus:ring-blue-9 disabled:cursor-not-allowed disabled:border-grey-5 disabled:bg-grey-1 disabled:text-grey-7',
  variants: {
    size: {
      'extra-large': 'h-12 md:text-16',
      large: 'h-10 md:text-14',
      medium: 'h-8 md:text-14',
      small: 'h-6 md:text-12',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export type InputVariants = VariantProps<typeof input>;

export type InputProps = Omit<ComponentPropsWithRef<'input'>, 'size'> & InputVariants;

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, size, ...restOfProps }, forwardedRef) => {
  return (
    <input className={input({ size, class: className })} data-testid="INPUT" ref={forwardedRef} {...restOfProps} />
  );
});

Input.displayName = 'Input';
