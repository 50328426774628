import { Workspaces } from '@air/api';
import { isAdminDotAirDotInc } from '@air/sysadmin';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';

export const WORKSPACES_LIST = 'WORKSPACES_LIST';

export const getWorkspacesListKey = (params?: Parameters<typeof Workspaces.list>['0']) => {
  return [WORKSPACES_LIST, params];
};

export const useWorkspaces = () => {
  const { isLoggedIn } = useIsLoggedIn();

  return useQuery(getWorkspacesListKey(), () => Workspaces.list(), {
    enabled: isLoggedIn && !isAdminDotAirDotInc(),
  });
};
